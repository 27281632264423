<template>
  <v-layout class="flex-column white pt-3 pb-6">
    <div class="flex-y-center flex-justify-between pb-2 px-4" style="border-bottom:1px solid #eee;">
      <div class=" flex-y-center">
        <div class="flex-y-center mr-1" style="width:150px;">
          <form-item
            v-model="search.data.userName"
            class="mr-0"
            placeholder="用户名"
            type="text"
            allow-close
            @onEnter="initList()"
          />
        </div>
        <div class="flex-y-center mr-1" style="width:320px; margin-left:10px">
          <form-item
            v-model="search.data.startTime"
            class="mr-1"
            placeholder="操作时间"
            allow-close
            type="date"
          />~
          <form-item
            v-model="search.data.endTime"
            class="mr-1"
            placeholder="操作时间"
            allow-close
            type="date"
          />
        </div>
        <div class="flex-y-center mr-1" style="width:150px; margin-left:10px">
          <form-item
            v-model="search.data.ip"
            class="mr-0"
            placeholder="IP"
            type="text"
            allow-close
            @onEnter="initList()"
          />
        </div>
        <div class="flex-y-center mr-1" style="width:150px; margin-left:10px">
          <form-item
            v-model="search.data.operateType"
            class=" flex-0 flex-shrink-0"
            :options="[{text:'登陆',value:'1'},{text:'退出',value:'2'}]"
            placeholder="登陆状态"
            style="width: 150px"
            dense
            type="select"
            allow-close
          />

        </div>
        <v-btn dark small color="primary" class="mr-1" depressed height="30" min-width="34" style="padding:0;" @click="initList()">
          <v-icon size="18">mdi-magnify</v-icon>
        </v-btn>
        <!-- <v-btn dark small color="grey lighten-1" depressed min-width="34" height="30" style=" padding:0;" @click="search.data.roleScope='',search.data.statusFlag='',initList()">
          <v-icon size="18">mdi-export</v-icon>
        </v-btn> -->
        <exportBtn :url="$api.settingExportLoginLogList" :parmas="{...search.data}" />
      </div>

    </div>
    <div class="">
      <v-data-table
        :headers="headers"
        :items="list"
        :items-per-page="search.pageSize"
        class="pb-5 max-width  pa-4 header-grey"
        hide-default-footer
        :loading="listLoading"
        item-key="id"
        disable-sort
        no-data-text="暂无查询数据"
      >
        <template v-slot:item.operateType="{item}">
          <span>{{ item.operateType==1?'登录':'退出' }}</span>
        </template>
      </v-data-table>
      <div class="mt-2 pr-2 mb-5 max-width  flex-justify-end">
        <pagination v-model="search.pageNo" :size="search.pageSize" :total="total" @input="getList" @changePageSize="changePageSize" />
      </div>
    </div>
  </v-layout>
</template>

<script>
export default {
  data() {
    return {
      search: {
        pageSize: 10,
        pageNo: 1,
        data: {}
      },
      total: 0,
      listLoading: false,
      headers: [
        // { text: '序号', value: 'rowIndex', width: '50', sortable: false },
        { text: '用户名', value: 'userName' },
        { text: '操作时间', value: 'createTime' },
        { text: 'IP', value: 'ip' },
        { text: '登陆状态', value: 'operateType' }
      ],
      list: []
    }
  },
  watch: {
  },
  mounted() {
    this.initList()
  },
  methods: {
    initList() {
      this.search.pageNo = 1
      this.getList()
    },
    // 改变每页的条数
    changePageSize(e) {
      this.search.pageSize = e
      this.search.pageNo = 1
      this.$forceUpdate()
      this.getList()
    },
    //  获取列表
    getList() {
      this.total = 0
      this.listLoading = true
      const search = { ...this.search.data }
      search.pageNo = this.search.pageNo
      search.pageSize = this.search.pageSize
      this.$http.get(this.$api.settingGetLoginLogList, { data: { ...search }}).then(res => {
        console.log('列表', res)
        this.listLoading = false
        this.list = res.result && res.result.records || []
        this.total = res.result && res.result.total || 0
      }).catch(() => {
        this.listLoading = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.form-title{
    width:160px;
    color: #777;
}
// ::v-deep .theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:not(:last-child)>td:last-child, .theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:not(:last-child)>td:not(.v-data-table__mobile-row), .theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:not(:last-child)>th:last-child, .theme--light.v-data-table>.v-data-table__wrapper>table>tbody>tr:not(:last-child)>th:not(.v-data-table__mobile-row), .theme--light.v-data-table>.v-data-table__wrapper>table>thead>tr:last-child>th {
//     border-bottom: thin solid rgb(156, 156, 156, .12) !important;
// }

</style>
